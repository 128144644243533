import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1100px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
`;

export const Body = styled.main`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
`;

export const Title = styled.h1`
  font-size: 4.6rem;
  margin-bottom: 1rem;
  font-weight: bold;
  letter-spacing: -0.38px;

  @media only screen and (min-device-width: 320px) and (max-width: 768px) {
    font-size: 2.6rem;
  }
`;

export const Subtext = styled.p`
  margin-bottom: 0.5rem;
  font-size: 2rem;

  @media only screen and (min-device-width: 320px) and (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

export const ListHeader = styled.div`
  border-bottom: 0.3px solid black;
  padding-bottom: 0.5rem;
`;

export const ListHeading = styled.div`
  font-weight: bold;
  font-size: 1.4rem;
  display: inline-block;
`;

export const ListCounter = styled.div`
  font-size: 1.4rem;
  float: right;
`;

export const FormSectionWrapper = styled.div`
  margin: 15px 0;
`;

export const InputGroup = styled.div`
  margin-bottom: 20px;
`;

export const Label = styled.label`
  font-size: 13px;
  display: block;
`;

export const TextField = styled.input`
  display: block;
  width: ${(p) => {
    if (p.showInline) {
      return "100%";
    } else {
      return p.halfWidth ? "40%" : "80%";
    }
  }};
  @media only screen and (min-device-width: 320px) and (max-width: 768px) {
    width: ${(p) => {
      if (p.showInline) {
        return "100%";
      } else {
        return p.halfWidth ? "50%" : "100%";
      }
    }};
  }
  height: 34px;
  padding: 0.375rem 0.75rem;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #495057;
  background-color: #f5f5f5;
  background-clip: padding-box;
  border: 1px solid #f5f5f5;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: 5px;
`;

export const TextArea = styled.textarea`
  display: block;
  width: ${(p) => {
    if (p.showInline) {
      return "100%";
    } else {
      return p.halfWidth ? "40%" : "80%";
    }
  }};
  @media only screen and (min-device-width: 320px) and (max-width: 768px) {
    width: ${(p) => {
      if (p.showInline) {
        return "100%";
      } else {
        return p.halfWidth ? "50%" : "100%";
      }
    }};
  }
  height: 100px;
  padding: 0.375rem 0.75rem;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #495057;
  background-color: #f5f5f5;
  background-clip: padding-box;
  border: 1px solid #f5f5f5;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: 5px;
`;

export const TextFieldWrapper = styled.div`
  overflow: hidden;
`;

export const PrependText = styled.span`
  font-size: 17px;
  color: #bebebe;
  float: left;
  line-height: calc(34px + 0.75em);
  margin-right: 5px;
`;

export const PrimaryButton = styled.button`
  width: 171px;
  height: 42px;
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
`;

export const LoaderWrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
`;
