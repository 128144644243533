import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Container } from "../common/styled-components";
import { Link } from "gatsby";
import { FiMapPin } from "react-icons/fi";

export const Navbar = (props) => {
  const [postcode, setPostcode] = useState("");
  const [isEditPostcode, setIsEditPostcode] = useState(false);

  const handlePostcodeInput = (event) => {
    const postcode = event.target.value;
    if (postcode.length < 5) setPostcode(postcode);
  };

  const handlePostcodeChange = () => {
    const pcNum = parseInt(postcode);
    if (pcNum && pcNum.toString().length === 4) {
      localStorage.setItem("postcode", postcode);
    } else {
      setPostcode("Set postcode");
    }
    setIsEditPostcode(false);
  };

  useEffect(() => {
    const pc = localStorage.getItem("postcode");
    if (pc) setPostcode(pc);
    else setPostcode("Set postcode");
  }, []);

  return (
    <Nav>
      <Container>
        <NavBar>
          <a href="/">
            <Logo>takeitaway.</Logo>
          </a>
          <Location>
            <FiMapPin />
            {!isEditPostcode && (
              <Postcode onClick={() => setIsEditPostcode(!isEditPostcode)}>
                {postcode}
              </Postcode>
            )}
            {isEditPostcode && (
              <>
                <PostcodeEditor
                  onChange={handlePostcodeInput}
                  value={postcode}
                />
                <Submit onClick={handlePostcodeChange}>Change</Submit>
              </>
            )}
          </Location>
          <Menu>
            {props.navLinks.map((navLink) => (
              <NavBarLink to={navLink.link} key={navLink.title}>
                {navLink.title}
              </NavBarLink>
            ))}
          </Menu>
        </NavBar>
      </Container>
    </Nav>
  );
};

const Nav = styled.div`
  width: 100%;
  background-color: black;
  min-height: 50px;
  display: flex;
  align-items: center;
`;

const NavBar = styled.div`
  width: 100%;
  height: 100%;
`;

const Logo = styled.div`
  color: white;
  font-size: 2rem;
  font-weight: bold;
  line-height: 50px;
  display: inline-block;
`;

const Location = styled.div`
  float: right;
  color: white;
  line-height: 50px;
  font-size: 1.4rem;

  & svg {
    margin-right: 5px;
  }
`;

const Postcode = styled.span`
  letter-spacing: 0.5px;
  font-weight: bold;
  text-decoration: underline;
`;

const PostcodeEditor = styled.input.attrs({
  type: "number",
  inputmode: "numeric",
  pattern: "[0-9]*",
})`
  outline: none;
  border: none;
  border-bottom: 1px solid white;
  font-size: 1.6rem;
  width: 5em;
  text-align: center;
  background-color: transparent;
  color: white;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const Submit = styled.button`
  cursor: pointer;
  background-color: transparent;
  color: white;
  font-weight: bold;
  border: none;
  text-transform: uppercase;
`;

const Menu = styled.nav`
  float: right;
  flex-direction: row-reverse;

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    display: flex;
    float: none;
  }
`;

const NavBarLink = styled(Link)`
  font-weight: bold;
  float: right;
  line-height: 50px;
  color: white;
  font-size: 15px;
  margin-right: 30px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-decoration: none;

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
    margin-left: 0px;
    margin-right: 20px;
    line-height: 25px;
  }
`;
