import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Navbar } from "./Navbar";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix, navigate } from "gatsby";
import { createGlobalStyle } from "styled-components/macro";
import styled from "styled-components/macro";
import Footer from "./Footer";

const navLinks = [
  {
    link: "/contact-us",
    title: "HMU",
  },
  {
    link: "/about",
    title: "About Us",
  },
  {
    link: "/directory",
    title: "Directory",
  },
  {
    link: "/",
    title: "The Feed Feed",
  },
];

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();

  return (
    <div>
      <GlobalStyle />
      <Helmet>
        <html lang="en" style={{ fontSize: "62.5%" }} />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.png`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="https://takeitaway.today/" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/og-img.png`}
        />
        <meta property="og:description" content={description} />
      </Helmet>
      <Wrapper>
        <Navbar navLinks={navLinks} />
        {children}
      </Wrapper>
      <Footer navLinks={navLinks} />
    </div>
  );
};

const Wrapper = styled.div`
  min-height: 100vh;
`;

const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before { 
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
  }

  html {
    font-size: 62.5%;
  }

  body {
    box-sizing: border-box; 
    margin: 0;
    font-family: 'SpartanMB', 'Arial', sans-serif;
    overflow-x: hidden;
  }

  h1, h2, h3, h4, h5 {
    font-family: 'SpartanMB', 'Arial', sans-serif;
    font-weight: bold;
  }

  p {
    font-family: 'SpartanMB', 'Arial', sans-serif;
    line-height: 2.5rem;
    font-size: 1.6rem;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  button {
    font-family: 'SpartanMB', 'Arial', sans-serif;
  }
`;

export default TemplateWrapper;
