import React from "react";
import styled from "styled-components/macro";
import { GiHeartInside } from "react-icons/gi";
import { FaRegCopyright, FaCopyright } from "react-icons/fa";
import { Container } from "../common/styled-components";
import { Link } from "gatsby";

const Footer = (props) => {
  return (
    <FooterContainer>
      <Container>
        <FooterTopRow>
          <FooterTitle>
            <Copyright /> takeitaway. 2020
          </FooterTitle>
        </FooterTopRow>
        <FooterBottomRow>
          <FooterLinks>
            {props.navLinks.map((navLink) => (
              <FooterLink to={navLink.link} key={navLink.title}>
                {navLink.title}
              </FooterLink>
            ))}
            <FooterSubTitle>
              Built by JJ Lau with <GiHeartInside />
            </FooterSubTitle>
          </FooterLinks>
        </FooterBottomRow>
      </Container>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.footer`
  background-color: black;
  color: white;
  padding: 20px 0;
  text-align: center;
`;

const FooterTopRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;
const FooterBottomRow = styled.div``;

const Copyright = styled(FaCopyright)`
  height: 1.6rem;
  width: 1.6rem;
`;

const FooterTitle = styled.h3`
  flex-basis: 100%;
  font-size: 2.5rem;
  font-weight: bold;
  margin: 10px;
`;
const FooterSubTitle = styled.span`
  flex-basis: 100%;
  font-size: 1.25rem;
`;

const FooterLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  font-weight: bold;
`;

const FooterLink = styled(Link)`
  line-height: 50px;
  color: white;
  font-size: 15px;
  margin: 0 15px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  text-decoration: none;

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
    line-height: 25px;
    margin: 0 5px;
  }
`;
